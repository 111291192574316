import HeaderSevice from "../../../components/HeaderService";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { Form, Radio } from "antd";
import { setParamsType } from "../../../redux/Slices/serviceSlice";
import local, { getLocalStorage } from "../../../helper/localStorage";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import paths from "../../../config";

export const BookingType = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const data_booking = local.StorageGetSecure("data_booking");
  // setParamsType to redux
  const dispatch = useAppDispatch();
  const serviceName = useAppSelector((state) => state.service.serviceName);
  const { t, i18n } = useTranslation(["booking"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const handleBookingType = () => {
    const paramsType = form.getFieldValue("booking-type");
    dispatch(setParamsType(paramsType));
    // navigate("/user/booking/");
    navigate(paths.booking);
  };
  return (
    <div className="wrapper booking-type">
      <HeaderSevice text={serviceName} />
      <div className="booking-type content-container">
        <h1 className="booking-type-title booking-title">{t("chon_loai_dich_vu")}</h1>
        <Form form={form} onFinish={handleBookingType}>
          <Form.Item
            name="booking-type"
            rules={[{ required: true, message: t("vui_long_chon_dich_vu") }]}
            initialValue={data_booking?.booking_type ? data_booking?.booking_type : 1}
            className="booking-type-item"
          >
            <Radio.Group
              onChange={(e) => {
                local.StorageSetSecure("data_booking", { ...data_booking, booking_type: e.target.value });
              }}
              className="booking-type-group"
            >
              <Radio value={1} className="booking-type-checkbox">
                {t("lien_tuc")}
              </Radio>
              <Radio value={2} className="booking-type-checkbox">
                {t("dinh_ki")}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <div className="booking-button-wrapper">
            <button className="booking-button primary">{t("tiep_theo")}</button>
          </div>
        </Form>
      </div>
    </div>
  );
};
