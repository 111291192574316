import { useNavigate } from "react-router-dom";
import HeaderSevice from "../../../components/HeaderService";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getPathByIndex } from "../../../utils/func";
import { useEffect, useState } from "react";
import { getServiceDetail, setServiceName, setTemplateType } from "../../../redux/Slices/serviceSlice";
import { useTranslation } from "react-i18next";
import paths from "../../../config";
import { Steps } from "antd";

export const ServiceDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = getPathByIndex(2)?.toString();
  const serviceData = useAppSelector((state) => state.service.serviceDetail);
  const { t } = useTranslation(["service"]);
  const [showingMore, setShowingMore] = useState({ introduce: false, working: false, not_working: false });

  useEffect(() => {
    if (id) {
      dispatch(getServiceDetail(id));
    }
  }, [navigate, id, dispatch]);

  const handleClickBookingService = () => {
    dispatch(setTemplateType(serviceData?.template_type));
    dispatch(setServiceName(serviceData?.name));
    // navigate("/user/booking/map");
    navigate(paths.bookingMap);
  };

  return (
    <div className="wrapper service-info">
      <HeaderSevice text={serviceData ? serviceData.name : ""} />
      <div className="content content-container has-bottom-button">
        <div className="banner">
          <img src={serviceData ? serviceData.image : ""} alt="" />
        </div>
        <div className="description">
          <div className="description-wrapper">
            {/* <span className="content-title">Giới thiệu dịch vụ</span> */}
            <span className="content-title">{t("gioi_thieu_dich_vu")}</span>
            {/* <div className="content-text"> */}
            <p className={`content-text${showingMore.introduce ? " read-less" : " read-more"}`}>
              {serviceData ? serviceData.introduce : ""}
            </p>
            <span
              className="text-action"
              onClick={() => setShowingMore({ ...showingMore, introduce: !showingMore.introduce })}
            >
              {showingMore.introduce ? t("an_bot") : t("xem_them")}
            </span>
            {/* </div> */}
          </div>
          <div className="description-wrapper">
            {/* <span className="content-title">Nhân viên của Bluecare sẽ thực hiện những công việc gì?</span> */}
            <span className="content-title">{t("nhung_cong_viec_chung_toi_lam")}</span>
            <p className={`content-text${showingMore.working ? " read-less" : " read-more"}`}>
              {serviceData ? serviceData.working : ""}
            </p>
            <span
              className="text-action"
              onClick={() => setShowingMore({ ...showingMore, working: !showingMore.working })}
            >
              {showingMore.working ? t("an_bot") : t("xem_them")}
            </span>
          </div>
          <div className="description-wrapper">
            {/* <span className="content-title">Các công việc không bao gồm thực hiện</span> */}
            <span className="content-title">{t("cac_cong_viec_khong_bao_gom_thuc_hien")}</span>
            <p className={`content-text${showingMore.not_working ? " read-less" : " read-more"}`}>
              {serviceData ? serviceData.not_working : ""}
            </p>
            <span
              className="text-action"
              onClick={() => setShowingMore({ ...showingMore, not_working: !showingMore.not_working })}
            >
              {showingMore.not_working ? t("an_bot") : t("xem_them")}
            </span>
          </div>
          <div className="description-wrapper">
            {/* <span className="content-title">Quy trình thực hiện</span> */}
            <span className="content-title">{t("quy_trinh_thuc_hien")}</span>
            <div className="content-procedure">
              {/* <div className="carousel">
                {serviceData?.procedure?.length > 0
                  ? serviceData.procedure.map((item: any, index: number) => (
                      <div className="carousel-item" key={index}>
                        <span className="carousel-item-title">{`${t("buoc")} ${index + 1}`}</span>
                        <span className="carousel-item-text">{item}</span>
                      </div>
                    ))
                  : ""}
              </div> */}
              <Steps
                items={serviceData?.procedure?.map((description: string, index: number) => ({
                  title: `Bước ${index + 1}`,
                  description,
                  status: "process",
                }))}
                direction="vertical"
                className="procedure-step"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="booking-button-wrapper">
        <button onClick={handleClickBookingService} className="booking-button primary">
          {/* Xem báo giá dịch vụ */}
          {t("xem_bao_gia_dich_vu")}
        </button>
      </div>
    </div>
  );
};
