import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// import FacebookLogin from "react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import AppleSignin from "react-apple-signin-auth";
import { notification } from "antd";
// import { useTranslation } from "react-i18next";

import logo_bluecare from "../../../assets/images/image_logo_full.png";
import icon_user from "../../../assets/icons/icon_user.png";
import icon_password from "../../../assets/icons/icon_password.png";
import icon_show_password from "../../../assets/icons/icon_show_password.png";
import icon_hide_password from "../../../assets/icons/icon_hide_password.png";
// import icon_facebook from "../../../assets/icons/icon_facebook.png";
import icon_google from "../../../assets/icons/icon_google.png";

import overLoader from "../../../components/overLoader";
import { authApi } from "../../../api/authApi";
// import { FACEBOOK_APP_ID } from "../../../utils/auth";
import { useAppDispatch } from "../../../redux/hook";
import { getInfoAccount } from "../../../redux/Slices/userSlice";
import { setRole } from "../../../redux/Slices/AuthSlice";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import paths from "../../../config";

export const Login = () => {
  // const { t } = useTranslation();
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const [api, showPopup] = notification.useNotification();
  const dispatch = useAppDispatch();
  // const userInfo = useAppSelector((state) => state.user.userInfo);
  const { t, i18n } = useTranslation(["auth", "common", "setting"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin: any = async (data: any, caseLogin: number) => {
    setStatusLoader(true);
    if (caseLogin === 1) {
      /* LOGIN GOOGLE */
      try {
        const resData: any = await authApi.loginGoogle({ access_token: data?.access_token });
        if (resData.status === 1) {
          localStorage.setItem("access_token", JSON.stringify(resData.data.token));
          setStatusLoader(false);
          const resultAction = await dispatch(getInfoAccount());
          const userInfo = resultAction.payload;
          if (userInfo.password_is_null) {
            setTimeout(() => {
              alertSuccess(resData.message);
            }, 1000);
            // navigate("/user/create_password");
            navigate(paths.createPassword);
          } else {
            dispatch(setRole({ role: "user" }));
            setTimeout(() => {
              //   navigate("/user/home");
              navigate(paths.homeUser);
            }, 1000);
            alertSuccess(resData.message);
          }
        }
      } catch (err: any) {
        setStatusLoader(false);
        alertFail(err.response.data.message);
      }
    } else if (caseLogin === 2) {
      /* LOGIN FACEBOOK */
      try {
        const resData: any = await authApi.loginFacebook({ access_token: data?.accessToken, fb_id: data?.userID });
        if (resData.status === 1) {
          localStorage.setItem("access_token", JSON.stringify(resData.data.token));
          setStatusLoader(false);
          const resultAction = await dispatch(getInfoAccount());
          const userInfo = resultAction.payload;
          if (userInfo.password_is_null) {
            setTimeout(() => {
              alertSuccess(resData.message);
            }, 1000);
            // navigate("/user/create_password?role=user");
            navigate(`${paths.createPassword}?role=user`);
          } else {
            dispatch(setRole({ role: "user" }));
            alertSuccess(resData.message);
            setTimeout(() => {
              //   navigate("/user/home");
              navigate(paths.homeUser);
            }, 1000);
          }
        }
      } catch (err: any) {
        setStatusLoader(false);
        alertFail(err.response.data.message);
      }
    } else if (caseLogin === 3) {
      /* LOGIN APPLE */
      try {
        const resData: any = await authApi.loginApple({
          code: data?.authorization?.code,
          name: "",
          email: "",
          role: "user",
        });
        if (resData.status === 1) {
          localStorage.setItem("access_token", JSON.stringify(resData.data.token));
          setStatusLoader(false);
          const resultAction = await dispatch(getInfoAccount());
          const userInfo = resultAction.payload;
          if (userInfo.password_is_null) {
            setTimeout(() => {
              alertSuccess(resData.message);
            }, 1000);
            // navigate("/user/create_password?role=user");
            navigate(`${paths.createPassword}?role=user`);
          } else {
            dispatch(setRole({ role: "user" }));
            alertSuccess(resData.message);
            setTimeout(() => {
              //   navigate("/user/home");
              navigate(paths.homeUser);
            }, 1000);
          }
        }
      } catch (err: any) {
        setStatusLoader(false);
        alertFail(err.response.data.message);
      }
    } else {
      /* Login Basic */
      try {
        const resData: any = await authApi.login(data);
        if (resData.status === 1) {
          localStorage.clear();
          localStorage.setItem("access_token", JSON.stringify(resData.data.token));
          setStatusLoader(false);
          alertSuccess(resData.message);
          dispatch(setRole({ role: "user" }));
          setTimeout(() => {
            // navigate("/user/home");
            navigate(paths.homeUser);
          }, 1000);
        }
      } catch (err: any) {
        setStatusLoader(false);
        alertFail(err.response.data.message);
      }
    }
  };

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => handleLogin(codeResponse, 1),

    onError: (error) => console.log("Login Failed: ", error),
  });

  return (
    <div className="wrapper login">
      {statusLoader ? overLoader() : ""}
      {showPopup}
      <div className="login container">
        <div className="login-logo">
          <img src={logo_bluecare} alt="" />
        </div>
        <h1 className="login-title">{t("dang_nhap_tai_khoan_khach_hang")}</h1>
        <div className="login-form">
          <form onSubmit={handleSubmit(handleLogin)} className="login-form-main">
            <div className="login-form-group">
              <input type="text" className="username" placeholder="Email" {...register("email", { required: true })} />
              <div className="icon">
                <img src={icon_user} alt="" />
              </div>
            </div>
            {/* <div className="label-require">{errors.email && <span>{t("common:yeu_cau_khong_de_trong")}</span>}</div> */}
            {errors.email && (
              <div className="label-require">
                <span>{t("common:yeu_cau_khong_de_trong")}</span>
              </div>
            )}
            <div className="login-form-group">
              <input
                type={showPassword ? "text" : "password"}
                className="password"
                placeholder={t("mat_khau")}
                {...register("password", { required: true })}
              />
              <div className="icon">
                <img src={icon_password} alt="" />
              </div>
              <div className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                <img src={showPassword ? icon_hide_password : icon_show_password} alt="" />
              </div>
            </div>
            {errors.password && (
              <div className="label-require">
                <span>{t("common:yeu_cau_khong_de_trong")}</span>
              </div>
            )}
            <div className="login-form-button">
              {/* <button className="button-register secondary">Đăng ký</button> */}
              {/* <Link to="/user/register" className="button-register auth-button secondary">
                {t("dang_ki")}
              </Link> */}
              <Link to={paths.register} className="button-register auth-button secondary">
                {t("dang_ki")}
              </Link>
              <button type="submit" className="button-login auth-button primary">
                {t("dang_nhap")}
              </button>
            </div>
          </form>
        </div>
        <div className="login-social">
          {/* <FacebookLogin
              appId={FACEBOOK_APP_ID}
              fields="name,email"
              cssClass="login-social-button"
              typeButton="button"
              disableMobileRedirect={true}
              icon={
                <div className="login-social-icon">
                  <img src={icon_facebook} alt="" />
                </div>
              }
              textButton="Đăng nhập bằng Facebook"
              callback={(data: any) => handleLogin(data, 2)}
            /> */}
          <button
            className="login-social-button"
            onClick={() => {
              handleLoginGoogle();
            }}
          >
            <div className="login-social-icon">
              <img src={icon_google} alt="" />
            </div>
            <span className="login-social-text">{t("dang_nhap_voi_google")}</span>
          </button>
          <AppleSignin
            authOptions={{
              clientId: "com.bluecare.dev.fe.services",
              scope: "name email",
              redirectURI: "https://bluecare-dev-fe.moncow.com/login",
              usePopup: true,
            }}
            uiType="light"
            className="apple-auth-btn login-social-button"
            noDefaultStyle={false}
            buttonExtraChildren={t("dang_nhap_voi_apple")}
            // onSuccess={(response: any) => console.log("Apple response: ", response)}
            // onError={(error: any) => console.error(error)}
            onSuccess={(response: any) => handleLogin(response, 3)}
            onError={(error: any) => handleLogin(error, 3)}
          />
        </div>
        <div className="forgot-password">
          {/* <Link to="/user/reset_password" className="forgot-password-button">
            {t("quen_mat_khau")}?
          </Link> */}
          <Link to={paths.resetPassword} className="forgot-password-button">
            {t("quen_mat_khau")}?
          </Link>
        </div>
        <Link
          // to="/terms_of_use"
          to={paths.terms}
          className="term-of-use"
        >
          {t("dieu_khoan_su_dung")}
        </Link>
      </div>
    </div>
  );
};
