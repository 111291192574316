import { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, notification, Popover, Select, Upload, UploadFile, UploadProps } from "antd";

import image_user from "../../../assets/images/image_user.png";
import { partnerApi } from "../../../api/partnerApi";
import overLoader from "../../../components/overLoader";
import { DataAccount, InfoAccount } from "../../../types/common";
import { validatePhoneNumber } from "../../../utils/validate";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import local, { getLocalStorage } from "../../../helper/localStorage";
import HeaderSevice from "../../../components/HeaderService";
import Paragraph from "antd/es/typography/Paragraph";

export const MyAccountPartner = () => {
  const [form] = Form.useForm();
  //   const navigate = useNavigate();
  //   const dispatch = useAppDispatch();
  const [dataAccount, setdataAccount] = useState<DataAccount>();
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [api, showPopup] = notification.useNotification();
  const [file, setFile] = useState<File | undefined>();
  const [documents, setDocuments] = useState<UploadFile[]>([]);
  const [urlImgPreview, setUrlImgPreview] = useState<any>("");
  const { t, i18n } = useTranslation(["setting", "common"]);
  const languageLocal = getLocalStorage("language");

  const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];

  useEffect(() => {
    getInfoAccount();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileImg = event.target.files?.[0];
    if (fileImg) {
      if (!allowedImageTypes.includes(fileImg.type)) {
        alertFail(t("chon_file_anh"));
        return;
      }
      setFile(fileImg);
      const readerFile = new FileReader();
      readerFile.onload = (event) => {
        if (event.target && event.target.result) {
          const urlImgPreview = event.target.result as string;
          setUrlImgPreview(urlImgPreview);
        }
      };
      readerFile.readAsDataURL(fileImg);
    }
  };

  const getInfoAccount = async () => {
    setStatusLoader(true);
    try {
      const resData: any = await partnerApi.getInfoPartner();
      if (resData.status === 1) {
        setStatusLoader(false);
        setdataAccount(resData.data);
        form.setFieldsValue({
          name: resData.data.name,
          gender: resData.data.gender,
          birthday: resData?.data?.birthday ? dayjs(resData?.data?.birthday) : dayjs(),
          telephone: resData.data.telephone,
          email: resData.data.email,
          address: resData.data.address,
          invite_code: resData.data.invite_code,
        });
      } else if (resData.status === 0) {
        setStatusLoader(false);
        alertFail(resData.message);
      } else {
        setStatusLoader(false);
        alertFail(resData.message);
      }
    } catch (err: any) {
      setStatusLoader(false);
      alertFail(err.response?.data?.message);
    }
  };

  const handleSaveInfo = async (dataAcc: InfoAccount) => {
    const { name, birthday, telephone, address, gender } = dataAcc;
    setStatusLoader(true);
    local.StorageRemoveSecure("partnerInfo");
    const data = new FormData();
    if (file) {
      data.append("avatar", file);
    }
    data.append("name", name);
    data.append("address", address);
    data.append("gender", gender);
    data.append("telephone", telephone);
    data.append("birthday", dayjs(birthday).format("YYYY-MM-DD"));
    if (documents) {
      documents.forEach((file) => {
        data.append("documents[]", file as any);
      });
    }
    // }
    try {
      const resData: any = await partnerApi.updateInfoPartner({
        data,
      });
      if (resData.status === 1) {
        setStatusLoader(false);
        alertSuccess(resData.message);
        setTimeout(() => {
          // documents.splice(0, documents.length);
          setDocuments([]);
          getInfoAccount();
        }, 1000);
      } else if (resData.status === 0) {
        setStatusLoader(false);
        alertFail(resData.message);
      } else {
        setStatusLoader(false);
        alertFail(resData.message);
      }
    } catch (err: any) {
      setStatusLoader(false);
      alertFail(err.response?.data?.message);
    }
  };

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };

  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };

  const content = (
    <div>
      <input type="file" className="relative-avt-upload" onChange={handleImageChange} />
    </div>
  );
  const props: UploadProps = {
    onRemove: (file) => {
      setDocuments((prev) => prev?.filter((item) => item.uid !== file.uid));
    },
    beforeUpload: (file) => {
      if (!allowedImageTypes.includes(file.type)) {
        alertFail("Vui lòng chọn file ảnh");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        alertFail("Ảnh phải nhỏ hơn 2MB!");
        return false;
      }
      setDocuments((prev) => (prev ? [...prev, file] : [file]));
      return false;
    },
    fileList: documents,
  };
  return (
    <div className="wrapper my-account">
      {statusLoader ? overLoader() : ""}
      {showPopup}
      <HeaderSevice text={t("chi_tiet_tai_khoan")} />
      <div className="my-account-info content-container">
        <div className="avatar">
          <Popover content={content} title={t("thay_anh_dai_dien")}>
            <img src={urlImgPreview ? urlImgPreview : dataAccount?.avatar ? dataAccount?.avatar : image_user} alt="" />
          </Popover>
        </div>
        <span className="name">{dataAccount?.name || ""}</span>
        <Form
          form={form}
          initialValues={{
            name: dataAccount?.name,
            gender: dataAccount?.gender,
            birthday: dataAccount?.birthday ? dayjs(dataAccount?.birthday) : dayjs(),
            telephone: dataAccount?.telephone,
            email: dataAccount?.email,
            address: dataAccount?.address,
            invite_code: dataAccount?.invite_code,
          }}
          name="basic"
          onFinish={handleSaveInfo}
          autoComplete="off"
          className="register-form"
          layout="vertical"
        >
          <div className="info">
            <Form.Item
              label={t("ho_ten")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("common:yeu_cau_khong_de_trong"),
                },
              ]}
              className="info-item"
            >
              <Input className="info-item-input" />
            </Form.Item>
            <Form.Item
              label={t("gioi_tinh")}
              name="gender"
              rules={[
                {
                  required: true,
                  message: t("common:yeu_cau_khong_de_trong"),
                },
              ]}
              className="info-item"
            >
              <Select
                options={[
                  { value: 1, label: "Nam" },
                  { value: 2, label: "Nữ" },
                  { value: 0, label: "Khác" },
                ]}
                variant="borderless"
                className="info-item-input"
              />
            </Form.Item>
            <Form.Item
              label={t("ngay_sinh")}
              name="birthday"
              rules={[
                {
                  required: true,
                  message: t("common:yeu_cau_khong_de_trong"),
                },
              ]}
              className="info-item"
            >
              <DatePicker format={["DD-MM-YYYY", "DD/MM/YYYY"]} className="info-item-input" />
            </Form.Item>
            <Form.Item label={t("so_dien_thoai")} required className="info-item">
              <Form.Item
                name="telephone"
                rules={[
                  {
                    required: true,
                    message: t("common:yeu_cau_khong_de_trong"),
                  },
                  {
                    validator: validatePhoneNumber,
                  },
                ]}
              >
                <Input type="text" className="info-item-input" />
              </Form.Item>
              {!dataAccount?.telephone_is_real && (
                <span className="info-item-error">{t("so_dien_thoai_chua_xac_minh")}</span>
              )}
              <span className="info-item-note">{t("su_dung_so_dien_thoai_co_dang_ki_zalo_de_nhan_thong_bao")}</span>
            </Form.Item>
            <Form.Item
              label={t("email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("email_khong_hop_le"),
                },
                {
                  required: true,
                  message: t("common:yeu_cau_khong_de_trong"),
                },
              ]}
              className="info-item"
            >
              <Input type="text" id="email" className="info-item-input" />
            </Form.Item>
            <Form.Item
              label={t("dia_chi")}
              name="address"
              rules={[
                {
                  required: true,
                  message: t("common:yeu_cau_khong_de_trong"),
                },
              ]}
              className="info-item"
            >
              <Input type="text" id="address" className="info-item-input" />
            </Form.Item>
            <Form.Item label={t("ma_gioi_thieu")} name="invite_code" className="info-item">
              <Paragraph copyable={{ tooltips: false }} className="info-item-input">
                {dataAccount?.invite_code}
              </Paragraph>
            </Form.Item>
            {dataAccount?.documents && dataAccount?.documents?.length > 0 && (
              <div className="info-item">
                <label className="info-item-label">{t("anh_ho_so")}</label>
                <div className="document-list">
                  {dataAccount?.documents?.map((item: any, index: number) => (
                    <div className="document-item" key={index}>
                      <img src={item} alt="" />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="info-item">
              <label className="info-item-label">{t("ho_so")}</label>
              <Form.Item name="documents">
                <Upload {...props} multiple>
                  <Button className="info-item-input button-upload">{t("chon_anh_ho_so")}</Button>
                </Upload>
              </Form.Item>
              <span className="info-item-note">{t("ghi_chu_chon_anh_ho_so")}</span>
            </div>
            {/* )} */}
          </div>
          <div className="save-changes">
            <button className="save-button primary">{t("common:luu")}</button>
          </div>
        </Form>
      </div>
    </div>
  );
};
