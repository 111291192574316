import { useEffect, useState } from "react";

import icon_phone from "../../../assets/icons/icon_phone.png";
import icon_back from "../../../assets/icons/icon_back.png";
import TheJobCard from "./components/TheJobCard";
import overLoader from "../../../components/overLoader";

import { Job } from "../../../types/common";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getJobsUser } from "../../../redux/Slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../helper/localStorage";
import paths from "../../../config";

export const TheJobs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState<number>(1);
  const dispatch = useAppDispatch();
  const allJobsUser = useAppSelector((state) => state.user.jobsUser);
  const { t, i18n } = useTranslation(["calendar", "home"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getJobsUser());
  }, [dispatch]);

  useEffect(() => {
    const hash = location.hash;
    if (hash === "#pending") setTab(1);
    else if (hash === "#process") setTab(2);
    else if (hash === "#completed") setTab(3);
    else if (hash === "#canceled") setTab(4);
  }, [location]);

  const renderJobs = () => {
    if (
      allJobsUser?.pending?.length > 0 ||
      allJobsUser?.process?.length > 0 ||
      allJobsUser?.complete?.length > 0 ||
      allJobsUser?.cancel?.length > 0
    ) {
      switch (tab) {
        case 1:
          return allJobsUser.pending.map((job: Job, index: number) => <TheJobCard job={job} key={index} />);
        case 2:
          return allJobsUser.process.map((job: Job, index: number) => <TheJobCard job={job} key={index} />);
        case 3:
          return allJobsUser.complete.map((job: Job, index: number) => <TheJobCard job={job} key={index} />);
        case 4:
          return allJobsUser.cancel.map((job: Job, index: number) => <TheJobCard job={job} key={index} />);
        default:
          return overLoader();
      }
    } else {
      return <></>;
    }
  };

  return (
    <div className="wrapper task">
      <div className="header">
        <button
          className="header-back-button"
          onClick={() =>
            // navigate("/user/home")
            navigate(paths.homeUser)
          }
        >
          <div className="header-back-icon">
            <img src={icon_back} alt="" />
          </div>
        </button>
        <span className="header-text">{t("home:cac_cong_viec")}</span>
        <a href="tel:0985768181" className="header-call">
          <img src={icon_phone} alt="" />
        </a>
      </div>
      <div className="task">
        <div className="task-navbar">
          <a className={`navbar-item ${tab === 1 ? "active" : ""}`} href="#pending" onClick={() => setTab(1)}>
            {t("dang_cho_thuc_hien")}
          </a>
          <a className={`navbar-item ${tab === 2 ? "active" : ""}`} href="#process" onClick={() => setTab(2)}>
            {t("dang_thuc_hien")}
          </a>
          <a className={`navbar-item ${tab === 3 ? "active" : ""}`} href="#completed" onClick={() => setTab(3)}>
            {t("da_hoan_thanh")}
          </a>
          <a className={`navbar-item ${tab === 4 ? "active" : ""}`} href="#canceled" onClick={() => setTab(4)}>
            {t("da_huy")}
          </a>
        </div>
        <div className="task-list">{renderJobs()}</div>
      </div>
    </div>
  );
};
