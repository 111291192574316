import { Routes, Route, Navigate } from "react-router-dom";
import { Fragment, Suspense } from "react";
import { Spin } from "antd";
import "./i18n";

import "./assets/styles/scss/style.scss";

import Page404 from "./page/404";
import { partnerRoutes, userRoutes, publicRoutes } from "./routes";
import ProtectedRoutes from "./routes/protectedRoutes";

import { getLocalStorage } from "./helper/localStorage";
import paths from "./config";

function App() {
  const role = getLocalStorage("role");
  const subDomain = window.location.hostname.split(".")[0];

  /* PUBLIC ROUTE */
  const publicRoute = publicRoutes.map((route) => {
    const Page = route.component;
    let Layout: any = Fragment;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Page />
          </Layout>
        }
      />
    );
  });

  /* PARTNER ROUTE */
  const partnerRoute = partnerRoutes.map((route: any) => {
    const Page = route.component;
    let Layout: any = Fragment;

    if (route.layout) {
      Layout = route.layout;
    } else if (route.layout === null) {
      Layout = Fragment;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          //check if role is user => navigate to partner login
          subDomain !== "partner" && !role && role !== "partner" ? (
            <Navigate to={paths.loginPartner} />
          ) : (
            <Layout>
              <Page />
            </Layout>
          )
        }
      />
    );
  });

  /* USER ROUTE */
  const userRoute = userRoutes.map((route: any) => {
    const Page = route.component;
    let Layout: any = Fragment;
    if (route.layout) {
      Layout = route.layout;
    } else {
      Layout = Fragment;
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          subDomain !== "app" && !role && role !== "user" ? (
            // <Navigate to="/user/login" />
            <Navigate to={paths.login} />
          ) : (
            <Layout>
              <Page />
            </Layout>
          )
        }
      />
    );
  });

  return (
    <div className="App">
      <Suspense fallback={<Spin />}>
        <Routes>
          {/* Default redirect to appropriate home based on role */}
          <Route path="/" element={<Navigate to="/home" />} />

          {/* {Login and Register} */}
          {publicRoute}

          {/* Page Partner + Page User */}
          <Route element={<ProtectedRoutes />}>
            {/* {[...partnerRoute, ...userRoute]} */}
            {subDomain === "partner" ? (
              partnerRoute
            ) : subDomain === "app" ? (
              userRoute
            ) : (
              <Navigate to="https://bluecare.vn" />
            )}
          </Route>

          {/* Page Not Found */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
