import { useEffect, useState } from "react";
import { Checkbox, Form, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";

// import { useTranslation } from "react-i18next";
import { authApi } from "../../../../api/authApi";
import { DataRegister } from "../../../../types/auth";
import overLoader from "../../../../components/overLoader";
import { validatePhoneNumber } from "../../../../utils/validate";
import image_user from "../../../../assets/images/image_user.png";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../../helper/localStorage";
import { COMPANY_PHONE } from "../../../../utils/common";
import icon_back from "../../../../assets/icons/icon_back.png";
import icon_phone from "../../../../assets/icons/icon_phone.png";
import paths from "../../../../config";

export const RegisterPartner = () => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [api, showPopup] = notification.useNotification();
  const { t, i18n } = useTranslation(["auth", "common"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };
  const handleRegister: any = async (data: DataRegister) => {
    setStatusLoader(true);
    try {
      const resData: any = await authApi.registerPartner({
        name: data.fullname,
        email: data.email,
        password: data.password,
        telephone: data.phone,
        invite: data.code,
      });
      if (resData.status === 1) {
        setStatusLoader(false);
        alertSuccess(resData.message);
        setTimeout(() => {
          //   navigate("/partner/login");
          navigate(paths.loginPartner);
        }, 1000);
      }
    } catch (err: any) {
      setStatusLoader(false);
      alertFail(err.response.data.message);
    }
  };

  return (
    <div className="wrapper register">
      <div className="header">
        <Link to="#" onClick={() => navigate(-1)}>
          <button className="header-back-button">
            <div className="header-back-icon">
              <img src={icon_back} alt="" />
            </div>
          </button>
        </Link>
        <span className="header-text">{t("dang_ki")}</span>
        <a href={`tel:${COMPANY_PHONE}`} className="header-call">
          <img src={icon_phone} alt="" />
        </a>
      </div>
      <div className="register content-container">
        {statusLoader ? overLoader() : ""}
        {showPopup}
        <div className="register-icon">
          <img src={image_user} alt="" />
        </div>
        <Form
          name="basic"
          onFinish={handleRegister}
          autoComplete="off"
          className="register-form"
          layout="vertical"
          //   style={{ fontSize: "22px" }}
        >
          <Form.Item
            className="register-form-group"
            label="Email"
            name="email"
            hasFeedback
            rules={[
              {
                type: "email",
                message: t("email_khong_hop_le"),
              },
              {
                required: true,
                message: t("common:yeu_cau_khong_de_trong"),
              },
            ]}
          >
            <Input placeholder="Email" className="register-form-input" />
          </Form.Item>
          <Form.Item
            className="register-form-group"
            label={t("ho_ten")}
            name="fullname"
            hasFeedback
            rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
          >
            <Input placeholder={t("ho_ten")} className="register-form-input" />
          </Form.Item>
          <Form.Item
            className="register-form-group"
            label={t("so_dien_thoai")}
            name="phone"
            hasFeedback
            rules={[
              { required: true, message: t("common:yeu_cau_khong_de_trong") },
              {
                validator: validatePhoneNumber,
              },
            ]}
          >
            <Input placeholder={t("so_dien_thoai")} className="register-form-input" type="text" />
          </Form.Item>
          <Form.Item
            className="register-form-group"
            label={t("mat_khau")}
            name="password"
            hasFeedback
            rules={[{ required: true, message: t("common:yeu_cau_khong_de_trong") }]}
          >
            <Input.Password placeholder={t("mat_khau")} className="register-form-input" />
          </Form.Item>
          <Form.Item
            className="register-form-group"
            label={t("nhap_lai_mat_khau")}
            name="confirm_password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: t("common:yeu_cau_khong_de_trong"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("mat_khau_khong_khop")));
                },
              }),
            ]}
          >
            <Input.Password placeholder={t("nhap_lai_mat_khau")} className="register-form-input" />
          </Form.Item>
          <Form.Item className="register-form-group" label={t("ma_gioi_thieu")} name="code">
            <Input placeholder={t("ma_gioi_thieu")} className="register-form-input" />
          </Form.Item>
          <Form.Item
            className="register-form-group"
            name="checkbox"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error(t("yeu_cau_dong_y_dieu_khoan"))),
              },
            ]}
          >
            <Checkbox className="register-terms-checkbox">{t("dong_y_dieu_khoan")}</Checkbox>
          </Form.Item>
          <button type="submit" className="button-register primary">
            {t("dang_ki")}
          </button>
        </Form>
        <Link
          // to="/partner/login"
          to={paths.loginPartner}
          className="return-login"
        >
          {t("tro_lai_dang_nhap")}
        </Link>
        <Link
          // to="/terms_of_use"
          to={paths.terms}
          className="term-of-use"
        >
          {t("dieu_khoan_su_dung")}
        </Link>
      </div>
    </div>
  );
};
