import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Carousel } from "antd";

import { getLocalStorage } from "../../../helper/localStorage";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getInfoAccount } from "../../../redux/Slices/userSlice";
import { getCategory } from "../../../redux/Slices/serviceSlice";
import paths from "../../../config";
import { BannerHomePartner } from "../../../types/common";

import ConfirmOTP from "../../../components/ModalConfirmOTP";
import overLoader from "../../../components/overLoader";

import icon_hamburger from "../../../assets/icons/icon_hamburger.png";
import icon_phone from "../../../assets/icons/icon_phone.png";
import image_logo_horizontal from "../../../assets/images/image_logo_horizontal.png";
import icon_calendar from "../../../assets/icons/icon_calendar.png";
import icon_star from "../../../assets/icons/icon_star.png";
import icon_list from "../../../assets/icons/icon_list.png";
import icon_relative from "../../../assets/icons/icon_relative.png";
import icon_notification from "../../../assets/icons/icon_notification.png";
import icon_statistic from "../../../assets/icons/icon_statistic.png";
import icon_about from "../../../assets/icons/icon_about.png";
import icon_user from "../../../assets/icons/icon_user.png";
import icon_setting from "../../../assets/icons/icon_setting.png";
import icon_info from "../../../assets/icons/icon_info.png";
import image_banner from "../../../assets/images/image_banner.png";
import icon_help from "../../../assets/icons/icon_help.png";

export const HomeUser = () => {
  const { t, i18n } = useTranslation(["home"]);
  const languageLocal: any = getLocalStorage("language");
  const [showSiderBar, setShowSiderBar] = useState<boolean>(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(true);

  //redux
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state: any) => state.user.userInfo);
  const loading = useAppSelector((state: any) => state.user.loading);
  const loadingSendOTP = useAppSelector((state: any) => state.auth.loading);
  const caregory = useAppSelector((state: any) => state.service?.caregory?.categories);
  const banners = useAppSelector((state: any) => state.service?.caregory?.banners);

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);
  useEffect(() => {
    if (!userInfo) {
      dispatch(getInfoAccount());
      dispatch(getCategory());
    }
  }, [dispatch, userInfo]);

  const renderedListMenuItem =
    caregory?.length > 0 &&
    caregory.map((item: any) =>
      userInfo?.telephone_is_real ? (
        <Link
          //   to={`/user/list_service/${item.id}`}
          to={paths.listServiceByCategory.replace(":category_id", item.id)}
          key={item.id}
          className="service-item"
        >
          <div className="service-item-icon">
            <img src={item.image} alt="" />
          </div>
          <span className="service-item-text">{item.name}</span>
        </Link>
      ) : (
        <div key={item.id} onClick={() => setIsPhoneVerified(false)} className="service-item">
          <div className="service-item-icon">
            <img src={item.image} alt="" />
          </div>
          <span className="service-item-text">{item.name}</span>
        </div>
      ),
    );

  // menu list wrapper
  const menuListWarapperItems = [
    { link: paths.calendarUser, img: icon_calendar, text: t("lich_cua_toi"), className: "menu-item" },
    { link: paths.promotionUser, img: icon_star, text: t("khuyen_mai"), className: "menu-item" },
    { link: paths.theJobsUser, img: icon_list, text: t("cac_cong_viec"), className: "menu-item" },
    { link: paths.familyInfoUser, img: icon_relative, text: t("thong_tin_nguoi_than"), className: "menu-item" },
    { link: paths.notificationUser, img: icon_notification, text: t("danh_sach_thong_bao"), className: "menu-item" },
    { link: paths.statisticUser, img: icon_statistic, text: t("thong_ke_giao_dich"), className: "menu-item" },
    { link: paths.healthKnowledgeUser, img: icon_about, text: t("kien_thuc_suc_khoe"), className: "menu-item" },
    { link: paths.freeCourseUser, img: icon_about, text: t("khoa_hoc_mien_phi"), className: "menu-item" },
    { link: paths.settingUser, img: icon_setting, text: t("cai_dat"), className: "menu-item" },
    { link: paths.helpUser, img: icon_help, text: t("tro_giup"), className: "menu-item" },
    { link: paths.aboutUser, img: icon_info, text: t("ve_bluecare"), className: "menu-item" },
  ];

  return (
    <div className="wrapper home">
      {loading && overLoader()}
      {loadingSendOTP && overLoader()}
      <div className="header">
        <button className="header-hamburger" onClick={() => setShowSiderBar(!showSiderBar)}>
          <div className="header-hamburger-icon">
            <img src={icon_hamburger} alt="" />
          </div>
        </button>
        <Link to={paths.homeUser} className="header-logo">
          <img src={image_logo_horizontal} alt="" />
        </Link>
        <a href="tel:0985768181" className="header-call">
          <img src={icon_phone} alt="" />
        </a>
      </div>
      <div className={`menu ${showSiderBar ? "active" : ""}`}>
        <Link
          // to="/user/setting/my_account"
          to={paths.myAccountUser}
          className="menu-account"
        >
          <div className="menu-account-image">
            <img src={userInfo?.avatar !== "" ? userInfo?.avatar : icon_user} alt="" />
          </div>
          <span className="menu-account-text">{userInfo?.name}</span>
        </Link>

        {/* banner here */}
        <div className="menu-list">
          <div className="menu-list-wrapper">
            {menuListWarapperItems.map((item, index) => (
              <Link key={index} to={item.link} className={item.className}>
                <div className="menu-item-icon">
                  <img src={item.img} alt={item.text} />
                </div>
                <span className="menu-item-text">{item.text}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="banner">
        <div className="banner-list">
          <div className="banner-item">
            <Carousel autoplay autoplaySpeed={2000} infinite>
              {banners?.length > 0 ? (
                banners.map((image: BannerHomePartner, index: number) => (
                  <div key={index}>
                    <img src={image.image} alt={`Slide ${index + 1}`} className="slider-image" />
                  </div>
                ))
              ) : (
                <img src={image_banner} alt="" />
              )}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="service">
        <div className="service-list">{renderedListMenuItem}</div>
      </div>
      <div className={`overlay ${showSiderBar ? "active" : ""}`} onClick={() => setShowSiderBar(!showSiderBar)}></div>
      {/* check verify phone number */}
      <div className={`overlay ${!isPhoneVerified ? "active" : ""}`}>
        <ConfirmOTP onClose={() => setIsPhoneVerified(!isPhoneVerified)} userInfo={userInfo} role="user" />
      </div>
    </div>
  );
};
