import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Select, Button, Input } from "antd";

import HeaderSevice from "../../../components/HeaderService";
// import icon_search from "../../../assets/icons/icon_search.png";
import LoadingParagraph from "../../../components/skeleton";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getAddressSuggestions, getAllCity } from "../../../redux/Slices/serviceSlice";
import paths from "../../../config";
import local, { getLocalStorage } from "../../../helper/localStorage";
import { useTranslation } from "react-i18next";
import { removeAccents } from "../../../utils/func";
import { City, District, Ward } from "../../../types/common";

interface BookingMapSelect {
  value: string;
  label: string;
}
export const BookingMap = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cities = useAppSelector((state) => state.service.cities);
  const addressSuggestions = useAppSelector((state) => state.service.addressSuggestions);
  const loadingCity = useAppSelector((state) => state.service.loadingCity);
  const loading = useAppSelector((state) => state.service.loading);
  const templateType = useAppSelector((state) => state.service.templateType);
  const serviceName = useAppSelector((state) => state.service.serviceName);

  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<string | null>(null);
  const [selectedWard, setSelectedWard] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  //   const [value, setValue] = useState<string>("");
  const [changeState, setChangeState] = useState<boolean>(false);
  const [suggesstionValue, setSuggesstionValue] = useState<string>("");
  //   const [options, setOptions] = useState<{ value: string }[]>([]);
  const [selectedState, setSelectedState] = useState<Array<boolean>>([false, false, false, false]);
  const { t, i18n } = useTranslation(["booking"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  useEffect(() => {
    dispatch(getAllCity());
    dispatch(getAddressSuggestions());
  }, [dispatch]);

  const onSelect = (data: string) => {
    setSelectedCity(data);
  };
  const handleCityChange = (data: string) => {
    setChangeState(true);
    // setValue(data);
    setSelectedCity(data);
    setSelectedState([true, false, false, false]);
    setSelectedDistrict(null);
    setSelectedWard(null);
    setAddress("");
    local.StorageSetSecure("data_booking", { city: data });
  };

  const handleDistrictChange = (value: string) => {
    setChangeState(true);
    setSelectedDistrict(value);
    selectedCity && setSelectedState([true, true, false, false]);
    setSelectedWard(null);
    local.StorageSetSecure("data_booking", { city: selectedCity, district: value });
  };

  const handleWardChange = (value: string) => {
    setChangeState(true);
    setSelectedWard(value);
    selectedDistrict && setSelectedState([true, true, true, false]);
    setAddress("");
    local.StorageSetSecure("data_booking", { city: selectedCity, district: selectedDistrict, ward: value });
  };

  const handleAddressChange = (e: any) => {
    setChangeState(true);
    setAddress(e.target.value);
    e.target.value.trim() !== ""
      ? setSelectedState([true, true, true, true])
      : setSelectedState([true, true, true, false]);
    // localStorage.setItem("data_booking", JSON.stringify({ city: selectedCity, district: selectedDistrict, ward: selectedWard, address: e.target.value }));
    local.StorageSetSecure("data_booking", {
      city: selectedCity,
      district: selectedDistrict,
      ward: selectedWard,
      address: e.target.value,
    });
  };

  useEffect(() => {
    templateType === 0 && navigate(paths.homeUser);
  }, [navigate, templateType]);
  useEffect(() => {
    // set change state to false when address is same as suggestion item
    if (
      addressSuggestions?.length > 0 &&
      addressSuggestions.includes(`${address}, ${selectedWard}, ${selectedDistrict}, ${selectedCity}`)
    ) {
      setChangeState(false);
      setSuggesstionValue(`${address}, ${selectedWard}, ${selectedDistrict}, ${selectedCity}`);
    }
  }, [addressSuggestions, address, selectedWard, selectedDistrict, selectedCity]);

  return (
    <div className="wrapper booking-map">
      <HeaderSevice text={serviceName} />
      <div className="booking-map content-container">
        {/* <div className="booking-map-search"></div> */}
        {loading || loadingCity ? (
          <LoadingParagraph />
        ) : (
          <>
            <h1 className="booking-map-title booking-title">{t("chon_dia_diem")}</h1>
            <div className="booking-map-list">
              {changeState ? (
                <div className="booking-map-item booking-map-text">
                  {/* {address, selectedWard, selectedDistrict, selectedCity} */}
                  {address}, {selectedWard}, {selectedDistrict}, {selectedCity}
                </div>
              ) : (
                addressSuggestions?.length > 0 && (
                  <Select
                    className="booking-map-item"
                    placeholder={t("dia_chi_goi_y")}
                    onSelect={(value) => {
                      const listAddress = value.split(", ");
                      const length = listAddress.length;
                      const city = cities?.find((city: City) => city?.FullName === listAddress[length - 1]);
                      const district = city?.District?.find(
                        (district: District) => district?.FullName === listAddress[length - 2],
                      );
                      const ward = district?.Ward?.find((ward: Ward) => ward?.FullName === listAddress[length - 3]);
                      setSelectedCity(city?.FullName);
                      setSelectedDistrict(district?.FullName);
                      setSelectedWard(ward?.FullName);
                      setAddress(listAddress.slice(0, length - 3).join(", "));
                      setSelectedState([true, true, true, true]);
                      local.StorageSetSecure("data_booking", {
                        city: city?.FullName,
                        district: district?.FullName,
                        ward: ward?.FullName,
                        address: listAddress.slice(0, length - 3).join(", "),
                      });
                    }}
                    value={suggesstionValue || undefined}
                  >
                    {addressSuggestions.map((item: any, index: number) => (
                      <Select.Option key={index} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                )
              )}
              {cities?.length > 0 && (
                <>
                  <Select
                    showSearch
                    //   options={cities?.map((city: any) => ({ value: city.FullName, label: city.FullName }))}
                    options={cities
                      .map((city: City) => ({ value: city.FullName, label: city.FullName }))
                      .sort((a: BookingMapSelect, b: BookingMapSelect) =>
                        removeAccents(a.label) > removeAccents(b.label) ? 1 : -1,
                      )}
                    optionFilterProp="value"
                    filterOption={(input, option) =>
                      removeAccents(option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(removeAccents(input).toLowerCase())
                    }
                    onSelect={onSelect}
                    onChange={handleCityChange}
                    placeholder={t("chon_thanh_pho")}
                    className="booking-map-item"
                    value={selectedCity}
                  />
                  {selectedCity && (
                    <Select
                      showSearch
                      size="large"
                      placeholder={t("chon_quan_huyen")}
                      onChange={handleDistrictChange}
                      options={cities
                        ?.find((city: City) => city.FullName === selectedCity)
                        ?.District?.map((district: City) => ({ value: district.FullName, label: district.FullName }))
                        .sort((a: BookingMapSelect, b: BookingMapSelect) =>
                          removeAccents(a.label) > removeAccents(b.label) ? 1 : -1,
                        )}
                      filterOption={(input, option) =>
                        removeAccents(option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(removeAccents(input).toLowerCase())
                      }
                      value={selectedDistrict}
                      className="booking-map-item"
                    />
                  )}
                  {selectedDistrict && (
                    <Select
                      showSearch
                      size="large"
                      placeholder={t("chon_phuong_xa")}
                      onChange={handleWardChange}
                      options={cities
                        ?.find((city: City) => city.FullName === selectedCity)
                        ?.District?.find((district: District) => district.FullName === selectedDistrict)
                        ?.Ward?.map((ward: Ward) => ({ value: ward.FullName, label: ward.FullName }))
                        .sort((a: BookingMapSelect, b: BookingMapSelect) =>
                          removeAccents(a.label) > removeAccents(b.label) ? 1 : -1,
                        )}
                      filterOption={(input, option) =>
                        removeAccents(option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(removeAccents(input).toLowerCase())
                      }
                      value={selectedWard}
                      className="booking-map-item"
                    />
                  )}
                  {selectedWard && (
                    <Input
                      onChange={handleAddressChange}
                      value={address ?? ""}
                      placeholder={t("dia_chi_chi_tiet")}
                      className="booking-map-item booking-map-input"
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}

        <div className="box-confirm">
          {selectedState[0] && selectedState[1] && selectedState[2] && selectedState[3] ? (
            <Link
              //   to={templateType === 3 ? "/user/booking/type" : "/user/booking"}
              to={templateType === 3 ? paths.bookingType : paths.booking}
              state={{ address, selectedWard, selectedDistrict, selectedCity }}
              type="primary"
              // block
              className="confirm-btn primary"
              // onClick={handleConfirm}
            >
              {t("xac_nhan")}
            </Link>
          ) : (
            <Button className="confirm-btn primary" disabled>
              {t("xac_nhan")}
            </Button>
          )}
          {/* <Link
            to={templateType === 3 ? "/user/booking/type" : "/user/booking"}
            state={{ addres, selectedWard, selectedDistrict, selectedCity }}
            type="primary"
            // block
            className="confirm-btn primary"
            // onClick={handleConfirm}
          >
            Xác nhận
          </Link> */}
        </div>
      </div>
    </div>
  );
};
